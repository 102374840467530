@import url("../font/stylesheet.css");

/* -----------------------
Base styles
------------------------*/

a {
  text-decoration: underline;
  color: #000;
}

a:focus,
a:active {
  text-decoration: none;
}

a:hover {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

html {
  height: 100%;
  box-sizing: border-box;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
}

*:before,
*:after {
  box-sizing: inherit;
}

a:hover {
  color: #000;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
}

h2 {
  font-size: 24px;
}

body {
  font-style: normal;
  font-weight: normal;
}

/** Buttons Styles **/

.btn,
a.btn {
  display: inline-block;
  text-align: center;
  padding: 5px 20px 5px 20px;
  font-size: 22px;
  color: #fff;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  text-align: center;
  margin-bottom: 10px;
  background: #d51111;
  border: 1px solid #d51111;
  text-decoration: none;
  white-space: normal !important;
  font-family: "Myriad Pro Semibold";
  font-style: normal;
  font-weight: normal;

  font-weight: 700;

  font-style: normal;
}

.btn:hover,
a.btn:hover {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background: #970b0b;
  color: #fff;
  border: 1px solid #970b0b;
}

label {
  float: left;
  color: #4d4d4d;
  font-size: 12px;
  margin-bottom: 0px;
  margin-bottom: 3px;
  font-family: "Gotham";
  font-weight: bold;
  font-style: normal;
}

.container-ui {
  color: #e1e1e1;
  font-size: 18px;

  min-height: 100%;
  font-family: "Myriad Pro Regular";
  display: flex;
  flex-direction: column;
  background: url("../images/bg.jpg") repeat top left;
}

.main-ui {
  min-height: 100%;
  flex: 1;
  padding: 0 15px;
}

.main-flex {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  max-width: 1700px;
}
/** Buttons Styles **/

/** Footer Styles **/

.footer-ui {
  flex: 0;
  padding: 10px 15px 10px 15px;
  text-align: center;
  color: #fff;
  margin-top: 100px;
}

.footer-ui .logoFooter {
  margin-bottom: 20px;
}

.footer-ui p.cpyTxt {
  margin: 0px auto 0px auto;
  font-size: 10px;
  color: #000000;
  font-family: "Gotham";
  font-weight: 500;
  font-style: normal;
  width: 50%;
  margin-bottom: 15px;
}

.footer-uiNav {
  margin-bottom: 0px;
}

.footer-uiNav li,
.logos li {
  border-right: 1px solid #000000;
  display: inline-block;
}

.footer-uiNav li:last-child {
  border-right: none;
}

.left {
  padding-right: 50px;
  padding-top: 100px;
  flex: 2;
}
.left p span {
  color: #ff0000;
}
.left .sprayLogo {
  margin-bottom: 20px;
}
.right {
  margin-top: 100px;
  text-align: center;
  flex: 3;
}
.right img {
  width: 100%;
}
.right h3 {
  font-size: 22px;
  font-family: "Myriad Pro Semibold";
  font-style: normal;
  font-weight: normal;
  margin: 20px 0px;
}

.confirmation {
  text-align: center;
  padding-top: 100px;
}

.confirmation p {
  font-size: 22px;
  font-family: "Myriad Pro Semibold";
  font-style: normal;
  font-weight: normal;
}

.confirmation .sprayLogo {
  margin-bottom: 70px;
}
.desktopHide {
  display: none;
}

/* Media Qurries */

@media (max-width: 767px) and (orientation: portrait) {
  .landing-ui .left {
    padding-right: 0px;
    padding-top: 20px;
    text-align: center;
  }
  .landing-ui .right {
    margin-top: 10px;
  }
  .confirmation {
    text-align: center;
    padding-top: 20px;
  }
  .confirmation p {
    font-size: 18px;
  }
  .desktopHide {
    display: block;
  }
  .mobHide {
    display: none;
  }
  .desktopHide img {
    width: auto !important;
    margin: 20px 0px 30px 0px;
  }
}

@media (min-width: 1250px) {
  .main-flex {
    flex-direction: row;
    padding-left: 140px;
    padding-right: 140px;
  }
  .left {
    padding-left: 0;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .landing-ui .left {
    padding-right: 0px;
    padding-top: 20px;
    text-align: center;
  }
  .landing-ui .right {
    margin-top: 10px;
  }
  .confirmation {
    text-align: center;
    padding-top: 20px;
  }
  .confirmation p {
    font-size: 18px;
  }
  .desktopHide {
    display: block;
  }
  .mobHide {
    display: none;
  }
  .desktopHide img {
    width: auto !important;
    margin: 20px 0px 30px 0px;
  }
}

@media (min-width: 1024px) and (max-width: 1150px) {
  .left {
    padding-left: 50px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .main-flex {
    display: flex;
    flex-direction: column;
  }
  .left {
    padding-left: 50px;
  }
  .landing-ui .left {
    padding-right: 0px;
  }

  .confirmation {
    text-align: center;
  }
  .desktopHide {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .left {
    padding-left: 50px;
    padding-right: 40px;
    padding-top: 40px;
  }
  .landing-ui .right {
    margin-top: 40px;
  }
  .confirmation {
    text-align: center;
    padding-top: 40px;
  }
}

button:focus {
  outline: 0;
}
.videoPlayer {
  margin-top: 0;
}

@media (min-width: 501px) and (max-width: 767px) {
  .videoPlayer {
    margin-top: -20px;
  }
}

@media (min-width: 401px) and (max-width: 500px) {
  .videoPlayer {
    margin-top: -70px;
  }
}
@media (max-width: 400px) {
  .videoPlayer {
    margin-top: -90px;
  }
}
